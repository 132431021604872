export const mockupText = [
    {
        title: "Private",
        content: "Your personal branded app & website",
        img: "private.png",
        icon: "private.svg",
        iconAlt: "Private"
    },
    {
        title: "Live Market",
        content: "Post cash bids, view farmers offers and confirm deals instantly",
        img: "live_market.png",
        icon: "live_market.svg",
        iconAlt: "Live Market"
    },
    {
        title: "Full Portfolio",
        content: "Intergrates with your ERP so farmers can manage tickets, contracts and settlements",
        img: "full_portfolio.png",
        icon: "full_portfolio.svg",
        iconAlt: "Full Portfolio"
    },
    {
        title: "Grower CRM",
        content: "Consolidate noted and buying opportunities in one location",
        img: "grower_crm.png",
        icon: "grower_crm.svg",
        iconAlt: "Grower CRM"
    },
    {
        title: "Logistics",
        content: "Easily manage and schedule post harvest deliveries",
        img: "logistics.png",
        icon: "logistics.svg",
        iconAlt: "Logistics"
    },
    {
        title: "Agronomics",
        content: "Fulfill traceability requirements with growing season record keeping",
        img: "argonomic.png",
        icon: "argonomic.svg",
        iconAlt: "Agronomics"
    },
    {
        title: "News & Alerts",
        content: "Timely alerts and news keeping you and your farmers up to date",
        img: "news_alerts.png",
        icon: "news_alerts.svg",
        iconAlt: "News & Alerts"
    },
    {
        title: "Bin Management",
        content: "Farmers can keep track of volume and quality of on farm storage",
        img: "bin_management.png",
        icon: "bin_management.svg",
        iconAlt: "Bin Management"
    },
]
