import React from "react";

const FacebookIcon = () => {
  return (
      <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>Facebook</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Home" transform="translate(-528.000000, -4213.000000)" fillRule="nonzero">
                  <g id="Group-11-Copy-3" transform="translate(-1.000000, 4124.000000)">
                      <g id="Facebook" transform="translate(529.000000, 89.000000)">
                          <path d="M0,11.5 C0,5.14872507 5.14872507,0 11.5,0 C17.8512749,0 23,5.14872507 23,11.5 C23,17.8512749 17.8512749,23 11.5,23 C5.14872507,23 0,17.8512749 0,11.5 Z" id="Combined-Shape" fill="#1990FF" />
                          <path d="M12.6986618,18.2617493 L12.6986618,12.0051421 L14.425759,12.0051421 L14.6546367,9.84908062 L12.6986618,9.84908062 L12.7015961,8.7699511 C12.7015961,8.20761708 12.7550254,7.90630566 13.5626995,7.90630566 L14.6424103,7.90630566 L14.6424103,5.75 L12.9150686,5.75 C10.8402534,5.75 10.1099722,6.79592178 10.1099722,8.55483333 L10.1099722,9.84932481 L8.81666667,9.84932481 L8.81666667,12.0053863 L10.1099722,12.0053863 L10.1099722,18.2617493 L12.6986618,18.2617493 Z" id="Combined-Shape-path" fill="#FFFFFF" />
                      </g>
                  </g>
              </g>
          </g>
      </svg>
  );
}

export default FacebookIcon;
