export const team = [
        {
            name: "Rory O'Sullivan",
            position: "Founder, Chief Executive Officer",
            content: "Rory has held a diverse range of roles in the agricultural space over the past 18 years, from cleaning field bins on the family farm at Patchewollock to running a profitable commodity trading book at one of the world’s biggest funds. Travelling and witnessing inefficiencies and opaque supply chains cemented his vision for Grain Discovery.",
            education: "Rory studied Agricultural Science at the University of Melbourne.",
            img: 'rory.jpg',
            linkedIn: 'https://www.linkedin.com/in/rory-o-sullivan-421a8027/',
        },
        {
            name: "Ruairi Hanafin",
            position: "Founder, Chief Operating Officer / Architect",
            content: "Ruairi is a long time blockchain advocate and sees its potential in increasing social fairness and greater financial inclusion- particularly in rural regions. He has held positions in quantitative investments, researched alpha strategies, and headed the blockchain group at one of the world’s largest funds.",
            education: "Ruairi holds a PhD in Theoretical Physics from Trinity College from the University of Dublin.",
            img: 'ruairi.png',
            linkedIn: 'https://www.linkedin.com/in/ruairi-h-06387222/',
        },
        {
            name: "Peter Vincent",
            position: "Founder, Chief Technology Officer",
            content: "Peter has over 20 years experience in the commodities markets as a trader and entrepreneur. He has coded trading desk infrastructure and set up risk management systems for numerous commodities shops including his own boutique firm. His passion is data and he specializes in building intuitive visualizations and models for complex data sets.",
            education: "Peter holds a Master’s Degree in Engineering Physics from Queen’s University.",
            img: 'pete.jpg',
        },
        {
            name: "Hongli Li",
            position: "Director of Development",
            content: "Hongli is a software architect with 15+ years of experience designing, implementing, delivering, and supporting enterprise solutions with polyglot programming. He is passionate in using the right technologies to solve interesting business problems. His current interests are data science and blockchain technologies. A Kotlin evangelist, Hongli has been using it for both backend and android development for couple of years.",
            education: "Hongli has a Bachelor of Computer Science from York University.",
            img: 'hongli.jpg',
            linkedIn: 'https://www.linkedin.com/in/suchness/',
        },
        {
            name: "Warren Lamb",
            position: "Blockchain Engineer",
            content: "Warren is a computer engineer with a background in software development and project management, and has a specific interest in machine learning, equity/commodity trading, blockchain development, and crypto-economics. His past projects include experience in front and back end web development, web analytics, automated trading algorithms, and artificial intelligence.",
            education: "Warren has a Bachelor of Applied Science in Computer Engineering from Queen's University.",
            img: 'warren.jpg',
            linkedIn: 'https://www.linkedin.com/in/warren-k-p-lamb-3154b357/',
        },
        {
            name: "Scott MacNevin",
            position: "Financial Controller",
            content: "Scott has 25 years experience, across 3 countries -from accounting and internal auditing to project consulting for NGO's.",
            education: "Scott has a Bachelor of Accounting from the University of South Australia and a graduate certificate in Mathematical Sciences from Auckland University of Technology",
            img: 'scott.jpg',
            linkedIn: 'https://www.linkedin.com/in/scott-macnevin-01153774/',
        },
        {
            name: "Pete Oram",
            position: "Director of Business Development",
            content: "Pete has spent his 10 year career in the agriculture industry in Ontario, holding a variety of roles with both Cargill and Sollio Grain in their Grain and Crop Inputs businesses. Pete enjoys identifying and creating new commercial opportunities that fill an important need to customers and bringing them to market.",
            education: "Pete possesses a Master of Business Administration from the Rotman School of Management at the University of Toronto",
            img: 'peteO.png',
            linkedIn: 'https://www.linkedin.com/in/pete-oram-mba-05912314/',
        },
        {
            name: "Rui Pan",
            position: "Full Stack Developer",
            content: "Rui has been focusing on distributed ledger technologies and applications in supply chain and carbon market since he graduated from university. He has developed a strong interest in full cycle development through his exploration in innovative technologies and product design.",
            education: "Rui received his Master of Science degree in Control Systems, Electrical Engineering from University of Alberta.",
            img: 'rui.jpg',
        },
    {
        name: "Jignesh Patel",
        position: "Senior Software Developer",
        content: "Jignesh is a software developer with nine years experience having worked with many different organizations in the telecommunications and financial industries. and is particularly interest in blockchain technology and its many applications in agriculture. Masters in computer science from University of Bridgeport, CT, USA",
        education: "",
        img: 'jignesh.jpg',
    },
    {
        name: "Hannah Li",
        position: "Software Developer",
        content: "Hannah has over 5 years experience in mobile development of Android apps. She has a huge interest in agriculture science and blockchain technology and she is passionate about exposure to new technologies and gaining a new level of confidence in the field.\n"
        ,
        education: "Hannah holds a Master's Degree in Grassland Biodiversity from Gansu Agriculture University.\n",
        img: 'hannah.jpg',
    },
        {
            name: "Alison Matthews",
            position: "Public Relations",
            content: "Alison is a seasoned public affairs consultant with over a decade of experience working in the agriculture, cleantech and environmental space. A proven track record in digital media, public affairs, communications and government relations, former clients include: Conservative Party of Canada, Sustainable Development Technology Canada Renewable Industries Canada.",
            education: "Alison holds a Bachelor of Arts in Classical Studies from the University of Ottawa",
            img: 'ali.jpg',
        },
        {
            name: "Abhishek Pandey",
            position: "Business & Strategy Development",
            content: "Abhishek has over 10 years of experience in strategy and operations with Tata Group, BlackBerry and Startups. He is passionate about solving complex business problems and generating tangible values to the firms and customers.",
            education: "Abhishek holds a Master in Business Administration from Schulich School of Business, York University and Oxford Blockchain Strategy Program from Said Business School, University of Oxford. Abhishek is an alumnus of the Indian Institute of Technology (IIT)",
            img: 'abhi.JPG',
        },
        {
            name: "Eli Yakubov",
            position: "Software Developer Intern",
            content: "Eli is pursing a degree in Information Technology from York University. His previous experience includes simulating, testing, and visualizing blockchain networks and he is excited to see the positive impact of the technology as it is applied in a real-world setting. His other passions include swimming, basketball, and traveling.",
            education: "Bachelor of Commerce in Information Technology, York University.",
            img: 'elihs.jpg',
        },
        {
            name: "Nahid Alimohammadi",
            position: "Software Developer Intern",
            content: "Nahid is pursuing a Master of Science degree in Computer Science at York University. She has previously worked for more than 7 years in different areas of software development including analysis, design and development of enterprise systems, and has managed several software projects. Her current focus is on Blockchain engineering and analysis for solving real-world problems. Her Master's thesis is about exploring the performance and security characteristics of the Tangle blockchain through high-resolution simulation. As part of her academic research group, she has contributed to publications for international conferences on software engineering and blockchain, such as ICBC, the International Conference on Blockchain and Cryptocurrency.",
            education: "",
            img: 'nahid.jpeg',
        },

        {
            name: "Logan Kenelly",
            position: "Business Development",
            content: "Logan grew up on a dairy farm which led to studying agricultural business, and pursuing a career in the industry. Through the University of Guelph’s Co-op program, he has gained experience and insight into different aspects of the agriculture industry through previous positions with AGDATA and OMAFRA. Logan is excited for the opportunity to build on this experience at Grain Discovery.\n"
                ,
            education: "Bachelor of Commerce, Food and Agricultural Business, University of Guelph",
            img: 'logan.jpg',
        },
        {
            name: "Jake Schapelhouman",
            position: "Marketing Lead",
            content: "Jake is a Ryerson University student living in Toronto and hailing from Northern Ontario from the town of New Liskeard. Jake studies marketing with a specialization in consumer behaviour and psychology with a passion for writing and storytelling. He has an interest in the lives and stories of people and is incredibly excited to help create a brand message that tells our spectacular story",
            education: "",
            img: 'jschnaps.jpg',
        }

]

export const coops = [
    {
        name: "Reed Rogers",
        position: "Software Developer Intern",
        content: "Reed is pursuing a degree in Management Engineering from the University of Waterloo. Hailing from a family farm on the Northern Bruce Peninsula, he is excited to combine his degree with his farming background at Grain Discovery! Reed is interested in efficiently solving meaningful technical problems, and theatre, having written and directed the Waterloo Engineering play last term.",
        education: "Bachelor of Management Engineering, University of Waterloo.",
        img: 'reed.jpg'
    },
    {
        name: "Ryan Duan",
        position: "Software Developer Intern",
        content: "Ryan believes in blockchain's ability to increase transparency and equality in transacting peer-to peer, as well as shine a light on the opacity of products moving through the supply chain. His past projects include work with Hyperledger and other blockchain technologies. His interests include capital markets and full stack web development.",
        education: "Bachelor of Computer Science, Carleton University.",
        img: 'ryan.jpg'
    },
    {
        name: "Andrew Lamb",
        position: "Software Developer Intern",
        content: "Andrew is a software Engineer with experience working at a number of different startups around Toronto. Background in various web development technologies, both front-end and back-end. Believes in the importance of transparency and equal opportunity for the free market.",
        education: "Bachelor of Software Engineering, University of Waterloo",
        img: 'andrew.png'
    },
    {
        name: "Michael Vytlingam",
        position: "Software Developer Intern",
        content: "With a background in web development, Michael is a passionate problem solver with a keen interest in experimenting with new technologies, such as blockchain and machine learning. Takes pride in using new technology to solve the world's socioeconomic difficulties and recognizes the importance of transparency in today's world.",
        education: "Bachelor of Mechanical Engineering, University of Waterloo",
        img: 'michael.jpg'
    },
    {
        name: "Aishmit Khurana",
        position: "Software Developer Intern",
        content: "Enjoys building things, whether it be making music, programming, or even writing. With a passion for travelling, Aishmit has seen first hand the many hardships local farmers face when it comes to distribution of their yields and strongly believes in the company’s vision to revolutionize the industry.",
        education: "Bachelor of Science in Kinesiology, University of Waterloo",
        img: 'aishmit.jpg'
    },
]

export const advisors = [
    //GROUPS OF TWO
        {
            name: "The Honourable Lyle Vanclief",
            content: "Has been an active member of the agriculture industry for over 50 years. After farming for 25 years he was elected to the Canadian Federal Parliament. He retired from politics (elected four times) as the Federal Minister of Agricultural and Agri-Food. Lyle has been recognised with a Fellowship of the Agricultural Institute of Canada and inducted into the Canadian agricultural Hall of Fame. He resides on the family farm in Prince Edward County.",
            img: 'lyle.jpg'
        },
        {
            name: "Eric Wetlaufer",
            content: "Has over 30 years of experience in investment management. Eric was previously responsible for leading the $120 billion Public Market Investments Group at CPPIB. Prior managerial roles include: Group Chief Investment Officer, International at Fidelity Management & Research in Boston. Chief Investment Officer at Putnam Investments and Managing Director at Cadence Capital Management. Eric serves on the Board of Directors of TMX Group and Aion.",
            img: 'eric.png'
        },
        {
            name: "Jim Grey",
            content: "Has over 40 years experience in agricultural processing, including technical, plant management and senior executive roles in Canada, the US and Europe. Jim is the ex CEO of IGPC Ethanol Inc, one of the largest agri-business cooperatives in Ontario. Currently serves as the Chair of Renewable Industries Canada, Vice Chair of the AgEnergy Co-operative, and is a member of BioIndustrial Innovation’s Canada Board of Directors.",
            img: 'jim.jpg'
        },
        {
            name: "David Ewasuik",
            content: "With over 30 years of experience in the IT industry. Currently the Chairman CEO of Market Evolution group of companies, who develop and support traditional and blockchain Exchanges and Clearing organisations. Been involved in software set up of 80+ Exchanges and National CDS's. Currently a director of the Bahamas International Stock Exchange, various blockchain and technology committees and boards.",
            img: 'david.png'
        },
        {
            name: "Nicole Rogers",
            content: "Nicole was raised alongside her family’s agri-trading and marketing businesses including, Farms.com, North America’s first agriculture sector focused web platform. Before founding Agriprocity – an agricultural advisory and investment firm with a focus on building disruptive supply chains, she was the Trade Attaché for Agriculture and Agri-food Canada (UAE). Nicole is a delegate on the United Nations Committee for Food Security, a founding member of the Dubai Food Trade Advisory Group and is an active angel investor and advisor in multiple Agtech start-ups. ",
            img: 'nicole.png'
        },
        {
            name: "Victor Li",
            content: "Victor has over 20 years of investment research experience with leading Canadian pension funds, covering real estate, economics, and fixed income. His paper on real estate was published in Canadian Investment Review. Victor has been studying blockchain since 2014 and co-founded the CPPIB blockchain community in 2015. His research interests include cryptoeconomics and decentralized finance. He is on George Brown College's blockchain program advisory committee and was on Toronto Blockchain Week 2019’s steering committee. Victor is a CFA charterholder.",
            img: 'victor.png'
        },
        {
            name: "Cy Monley",
            content: "Cy has over 25 years of ag commodity experience. He is the currently the CEO of Silveus Financial, and on the executive committee of the Farmers Business Network.",
            img: 'cy.png'
        },
        {
            name: "David Pupo",
            content: "David has over 25 years of corporate and investment banking experience including, most recently, a dual role as Chief Risk Officer at a large agriculture company and VP Corporate Development and Strategic Partnerships at Genesis Grain & Fertilizer. Previously, David was the Global Head of Fertilizer Risk Management and Senior Equity Analyst at Macquarie Bank Limited covering the grain handling, agriculture equipment and fertilizer sectors.",
            img: 'davidP.png'
        },

]

export const supporters = [
    {
        name: "AgLaunch",
        img: "AgLaunch.png",
        link: "http://aglaunch.com/"
    },
    {
        name: "Creative Destruction Lab",
        img: "cdl.png",
        link: "https://www.creativedestructionlab.com/"
    },
    {
        name: "Ontario Agri-Food Technologies",
        img: "OAFT.png",
        link: "http://oaft.org/"
    },
    {
        name: "Canadian Seed Growers Association",
        img: "CSGA.png",
        link: "https://seedgrowers.ca/"
    },
    {
        name: "Community Futures: Prince Edward, Lennox, & Addington Counties",
        img: "community-futures.png",
        link: "http://www.pelacfdc.ca/"
    },
    {
        name: "ReWatt power",
        img: "ReWatt.png",
        link: "https://www.rewattpower.com/"
    },
    {
        name: "MaRs",
        img: "mars.jpg",
        link: "https://www.marsdd.com/"
    },
    {
        name: "Jonah Group",
        img: "jonahGroup.svg",
        link: "https://www.jonahgroup.com/"
    },
    {
        name: "Northumberland Community Futures Development Corporation",
        img: "Northumberland.png",
        link: "https://financingandstrategy.com/"
    },
    {
        name: "Onaterio Centres of Excellence",
        img: "once.jpg",
        link: "https://www.oce-ontario.org/"
    },
    {
        name: "Grain Farmers of Ontario",
        img: "gfo.jpg",
        link: "https://gfo.ca/"
    },

    {
        name: "Olds College",
        img: "olds-college.jpg",
        link: "https://www.oldscollege.ca/shopoc/brewery/index.html"
    },
    {
        name: "Decisive Farming",
        img: "decisive-farming.png",
        link: "https://decisivefarming.com/"
    },
    {
        name: "Government of Canada",
        img: "government.png",
    },

]
