import React from 'react';
import {Button} from 'antd';

import img from '../../../assets/images/home/market.png'
import './MarketYourGrain.scss'

const MarketYourGrain = (props) => {

    return (
        <div className="home-section-3">
            <div className="title">
                <h2>Market your grain your way</h2>
                <p>A personalized market digitizing the handshake between farmer and their Local grain buyers. <br/>
                    Buy or sell with live bids and target orders while managing your contracts on one app.</p>
            </div>

            <div className="content">
                <div className="img-wrapper">
                    <img src={img} alt="Market Your Grain Your Way"/>
                </div>

                <div className="footer">
                    <div className="footer-card-wrapper">
                        <div className="card">
                            <h2>Grain Buyers</h2>
                            <Button className="btn-primary" onClick={() => props.openDownloadModal(true)}>Grain Buyers</Button>
                        </div>
                        <div className="card">
                            <h2>Farmers</h2>
                            <Button className="btn-primary" onClick={props.openDownloadModal}><span>Download the App(s)</span></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default MarketYourGrain;
