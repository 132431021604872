import React, {useState, useEffect} from 'react';
import { Row, Col } from 'antd';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

// components
import Header from '../../components/header/Header';
import SubscribeBar from '../../components/subscribeBar/SubscribeBar';

// assets
import headerImg from '../../assets/images/headers/sunset.jpg'
import './Contact.css';

const Contact = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    document.title = "Contact Us — Grain Discovery"
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return function cleanup() {
        window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  }

  const TorontoMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 43.644910, lng: -79.396091 }}
      defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 43.644910, lng: -79.396091 }} />}
    </GoogleMap>
  ));

  const PictonMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 44.024687, lng: -77.120361 }}
      defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 44.024687, lng: -77.120361 }} />}
    </GoogleMap>
  ));
  const SaskatoonMap = withScriptjs(withGoogleMap((props) =>
      <GoogleMap
          defaultZoom={15}
          defaultCenter={{ lat: 52.122964, lng: -106.645318 }}
          defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
      >
        {props.isMarkerShown && <Marker position={{ lat: 52.122964, lng: -106.645318 }} />}
      </GoogleMap>
  ));
  const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
  const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=' + MAP_KEY + '&v=3.exp&libraries=geometry,drawing,places'

  return (
    <div className="Contact">
      <Header img={headerImg} alt="A combine working in a field as the sun is going down." />
      <div className="section-block get-in-touch">
        <h1>Get in touch with us</h1>
        <p>Grain Discovery is about collaboration and delivering benefit to everyone in the food supply chain.</p>
        <p>So if you’re a farmer, potential partner organisation, job seeker or simply interested in hearing about what we’re doing, first. Sign up below or contact us at <a href="mailto:info@graindiscovery.com" target="_blank" rel="noopener noreferrer" >info@graindiscovery.com</a>.</p>
        <p>We can't wait to start the conversation.</p>
      </div>

      <div className="section-block address">
        <Row>
          <Col span={width > 1000 ? 11 : 24}>
            <Row>
              <div>
                <p className="title">Prince Edward County</p>
                <p>12432 Loyalist Pkwy</p>
                <p>Prince Edward, ON, Canada</p>
                <p>2VF HRV</p>
              </div>
            </Row>
            <Row style={{marginTop:'1rem'}}>
              <div className="map">
                <PictonMap
                  isMarkerShown
                  googleMapURL={googleMapURL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `300px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </Row>
          </Col>
          <Col span={width > 11 ? 2: 24}></Col>

          <Col span={width > 1000 ? 11 : 24}>
            <Row>
              <div>
                <p className="title">Toronto</p>
                <p>461 King Street West</p>
                <p>Toronto, ON, Canada</p>
                <p>M5V 1K4</p>
              </div>
            </Row>
            <Row style={{marginTop:'1rem'}}>
              <div className="map">
                <TorontoMap
                  isMarkerShown
                  googleMapURL={googleMapURL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `300px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </Row>
          </Col>
          <Col span={width > 1000 ? 11 : 24}>
            <Row>
              <div>
                <p className="title">Saskatoon</p>
                <p>1015 15th St East</p>
                <p>Saskatoon, SK, Canada</p>
                <p>S7N 0R4</p>
              </div>
            </Row>
            <Row style={{marginTop:'1rem'}}>
              <div className="map">
                <SaskatoonMap
                    isMarkerShown
                    googleMapURL={googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </Row>
          </Col>
        </Row>

      </div>

      <div className="subscribe-container">
        <SubscribeBar />
      </div>

    </div>
  );
}

export default Contact;
