
//gd

export const press = 
[
    {
        title: "Spotlight on Grain Discovery",
        publication: "Future Farming",
        date: "April 2021",
        logo: "ff.png",
        link: "https://ontariograinfarmer.ca/flipbook/2021/2021%2001%20January/20/index.html#zoom=z"
    },
    {
        title: "Using Technology to Market Your Crop",
        publication: "Grain Farmers of Ontario",
        date: "January 2021",
        logo: "GFOMag.png",
        link: "https://ontariograinfarmer.ca/flipbook/2021/2021%2001%20January/20/index.html#zoom=z"
    },
    {
        title: "Ready Set Grow Podcast",
        publication: "RH Accelerator",
        date: "December 2020",
        logo: "rhAccelerator.png",
        link: "https://www.youtube.com/watch?v=kVrU1TrstZY&t=43s&ab_channel=RHAccelerator"
    },
    {
        title: "Modernizing how farmers and elevators do business ",
        publication: "Ontario Farmer",
        date: "November 2020",
        logo: "OntarioFarmer.png",
        link: "https://drive.google.com/file/d/1UAgyeejfetapvlOybNVgMADaSWqrCv4x/view"
    },
    {
        title: "Ontario Startup launches grain trading app",
        publication: "Farmtario",
        date: "October 2020",
        logo: "FarmtarioFormatted.png",
        link: "https://farmtario.com/news/ontario-start-up-launches-grain-trading-app/"
    },
    {// The top element in the list is highlighted on the home page, in HomePageNews
        title: "Grain Discovery Launches Grain Marketing App for Fall Harvest",
        publication: "Press Release",
        date: "Sept 2020",
        author: "Ruairi Hanafin",
        img: "tofu.png",
        alt: "GD Logo",
        link: "https://world.einnews.com/pr_news/526869000/grain-discovery-s-new-grain-marketing-app-released-for-fall-harvest",
        peak: "Grain Discovery launches grain trading app for Ontario elevators",
        logo: "GD.png",
        atl: "GD Logo"
    },
    {
        title: "Grain Discovery marketing app planned for U.S. in 2021  ",
        publication: "Successful Farming",
        date: "September 2020",
        logo: "SuccessfulFarming.png",
        link: "https://www.agriculture.com/news/technology/grain-discovery-marketing-app-planned-for-us-in-2021"
    },
    {
        title: "Grain Discovery's Beer Traceability Pilot",
        publication: "Press Release",
        date: "July 2020",
        author: "Alison Matthews",
        img: "tofu.png",
        alt: "GD Logo",
        link: "https://www.einpresswire.com/article/520286845/grain-discovery-and-olds-college-launch-a-beer-that-s-fully-traceable-from-field-to-glass",
        peak: "A recent pilot project explored the potential of blockchain technology to improve traceability within the grain sector.",
        logo: "GD.png",
        atl: "GD Logo"
    },
    {
        title: "Launch of traceable beer using blockchian technology",
        publication: "Foods In Canada",
        date: "June 2020",
        logo: "foodsInCanada.png",
        link: "https://www.foodincanada.com/products/olds-college-brewery-launches-traceable-beer-using-blockchain-technology-145584/"
    },
    {
        title: "Traceability beers shows barley’s path in Alberta",
        publication: "Farms.com",
        date: "June 2020",
        logo: "farm.com.png",
        link: "https://www.farms.com/ag-industry-news/traceable-beer-shows-barley-s-path-in-alta-459.aspx"
    },
    {
        title: "New brew shares field to glass",
        publication: "RD News",
        date: "June 2020",
        logo: "rdNews.png",
        link: "https://rdnewsnow.com/2020/06/18/new-olds-college-brew-shares-ingredients-story-from-field-to-glass/"
    },
    {
        title: "Grain Discovery & Olds College Launch Traceable Beer ",
        publication: "Press Release",
        date: "June 2020",
        logo: "GD.png",
        link: "https://mailchi.mp/graindiscovery/fieldtoglass"
    },
    {
        title: "Field to Glass",
        publication: "Farming For Tomorrow",
        date: "May 2020",
        logo: "farmingForTomorrow.png",
        link: "https://farmingfortomorrow.ca/ag-colleges/field-to-glass/"
    },
    {
        title: "Alumni Spotlight - Catching up with Ruairi Hanafin",
        publication: "CPP Investments",
        date: "Feb 2020",
        logo: "CPP.png",
        link: "https://www.cppinvestments.com/thekey/alumni-spotlight-catching-up-ruairi-hanafin"
    },
    {
        title: "What About Blockchain",
        publication: "John Deere Furrow",
        date: "Feb 2020",
        logo: "JohnDeereFurrow.png",
        link: "https://www.deere.com/assets/publications/index.html?id=b057a1b9#22"
    },
    {
        title: "Real Blockchain Payoffs",
        publication: "County Guide",
        date: "Feb 2020",
        logo: "countryGuide.png",
        link: "https://www.country-guide.ca/guide-business/real-blockchain-payoffs/"
    },
    {

        title: "Traceability through blockchain",
        publication: "Ontario Grain Farmer Magazine",
        date: "October 2019",
        author: "Treena Hein",
        img: "tofu.png",
        alt: "3 holstein cows looking at the camera",
        link: "https://ontariograinfarmer.ca/2019/10/01/traceability-through-blockchain/",
        peak: "A recent pilot project explored the potential of blockchain technology to improve traceability within the grain sector.",
        logo: "GFOMag.png",
        atl: "Ontario Grain Farmer Magazine logo"
    },
    {
        title: "Tracking 1,600+ Startups Innovating on the Farm & in the Supply Chain",
        publication: "Forbes",
        date: "3 September 2019",
        author: "Seana Day",
        link: "https://www.forbes.com/sites/themixingbowl/2019/09/03/agtech-landscape-tracking-1600-startups-innovating-on-the-farm-and-in-the-supply-chain/#28d492323b62",
        logo: "Forbes.png",
        alt: "Forbes logo"
    },
    {
        title: "From feedstock to by-products: Using blockchain to trace biofuels",
        publication: "Biofuels Digest",
        date: "4 June 2019",
        author: "Peter Vincent",
        link: "https://www.biofuelsdigest.com/bdigest/2019/06/04/from-feedstock-to-by-products-using-blockchain-to-trace-biofuels/",
        peak: "One of blockchain's most interesting use-cases is tracing agricultural products, including biofuels, back to their origin.",
        logo: "BiofuelsDigest.png",
        alt: "Biofuels Digest logo"
    },
    {
        title: "The Blockchain Revolution Begins",
        publication: "Germination",
        date: "9 May 2019",
        author: "Marc Zienkiewicz",
        link: "https://germination.ca/the-blockchain-revolution-begins/",
        logo: "Germination.png",
        alt: "Germination logo"
    },
    {
        title: "Next Generation of Food Traceability with Ontario Soybeans ",
        publication: "Press Release",
        date: "1st May 2019", 
        author:"Alison Matthews",
        link: "https://mailchi.mp/1e8d9422e93f/media-release-the-next-generation-of-food-traceability",
        logo: "GD.png",
        alt: "Grain Discovery logo"
    },
    {
        title: "Blockchain Bonanza",
        publication: "Biofuels Digest",
        date: "17 February 2019",
        author: "Helena Tavares Kennedy",
        link: "https://www.biofuelsdigest.com/bdigest/2019/02/17/blockchain-bonanza-how-blockchain-is-making-things-saner-from-farm-to-factory-and-beyond/",
        logo: "BiofuelsDigest.png",
        alt: "Biofuels Digest logo"
    },
    {
        title: "Blockchain sale a first ever in the grain industry",
        publication: "Ontario Farmer",
        date: "1 February 2019",
        author:"Lilian Schaer",
        link: "https://www.graindiscovery.com/s/blockchain.pdf",
        logo: "OntarioFarmer.png",
        alt: "Ontario Farmer logo"
    },
    {
        title: "Local producers first to net sale from online grain trading application",
        publication: "The Picton Gazette",
        date: "28 January 2019",
        author:"Jason Parks",
        link: "http://www.pictongazette.ca/2019/01/28/local-producers-first-to-net-sale-from-online-grain-trading-application/",
        logo: "PictonGazette.png",
        alt: "Picton Gazette logo"
    },
    {
        title: "Why we need more pubs: Interest rate levels are in the hands of entrepreneurial dreamers",
        publication: "Financial Post",
        date: "26 January 2019",
        author:"Kevin Carmichael",
        link: "https://business.financialpost.com/news/economy/why-we-need-more-pubs-interest-rate-levels-are-in-the-hands-of-entrepreneurial-dreamers",
        logo: "FinancialPost.png",
        alt: "Financial Post logo"
    },
    {
        title: "Grain Discovery executes corn transaction using blockchain",
        publication: "Successful Farming",
        date: "22 January 2019",
        author:"Laurie Bedord",
        link: "https://www.agriculture.com/news/technology/grain-discovery-executes-corn-transaction-using-blockchain",
        logo: "SuccessfulFarming.png",
        alt: "Successful Farming logo"

    },
    {
        title: "Ontario farmers make first blockchain system corn sale",
        publication: "Farmtario",
        date: "18 January 2019",
        author:"John Greig",
        link: "https://farmtario.com/crops/ontario-farmers-make-first-blockchain-system-corn-sale/",
        logo: "FarmtarioFormatted.png",
        alt: "Farmtario logo"
    },
    {
        title: "7 Ag Tech Advancements in 2018",
        publication: "Successful Farming",
        date: "27 November 2018",
        author:"Laurie Bedord",
        link: "https://www.agriculture.com/news/technology/7-ag-tech-advancements-from-2018",
        logo: "SuccessfulFarming.png",
        alt: "Successful Farming logo"
    },
    {
        title: "Reaping potential grain profits with blockchain",
        publication: "Farmtario",
        date: "27 November 2018",
        author:"Lois Harris",
        link: "https://farmtario.com/news/reaping-potential-grain-profits-with-blockchain/",
        logo: "FarmtarioFormatted.png",
        alt: "Farmtario logo"
    },
    {
        title: "Blockchain will improve farmers' ability to market and sell crops",
        publication: "Successful Farming",
        date: "20 November 2018",
        author:"Laurie Bedord",
        link: "https://www.agriculture.com/technology/data/blockchain-technology-will-improve-farmers-ability-to-market-and-sell-crops",
        logo: "SuccessfulFarming.png",
        alt: "Successful Farming logo"
    },
    {
        title: "Farming blockchain",
        publication: "Farmtario",
        date: "6 September 2018",
        author:"John Greig",
        link: "https://farmtario.com/news/can-blockchain-technology-make-the-agriculture-supply-chain-more-efficient/",
        logo: "FarmtarioFormatted.png",
        alt: "Farmtario logo"
    },
    {
        title: "Information-sharing in the beef value chain still in its infancy",
        publication: "Farmtario",
        date: "20 August 2018",
        author:"Stew Slater",
        link: "https://farmtario.com/news/information-sharing-in-the-beef-value-chain-still-in-its-infancy/?module=carousel&pgtype=homepage&i=1",
        logo: "FarmtarioFormatted.png",
        alt: "Farmtario logo"
    },
    {
        title: "Companies to present at 2018 Ag Innovation Showcase",
        publication: "Ag Innovation Showcase",
        date: "1 August 2018",
        link: "http://blog.agshowcase.com/2018/08/01/companies-to-present-at-2018-ag-innovation-showcase-span-farm-to-table/",
        logo: "AgInnovationFormatted.png",
        alt: "Ag Innovation Showcase logo"
    },

]
