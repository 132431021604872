import React, {useEffect} from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

// components
import { openings } from '../../data/pages/careers/jobOpenings';
import Header from '../../components/header/Header';

// assets
import headerImg from '../../assets/images/headers/round-field.jpg';
import './Careers.css';

const Careers = () => {
  useEffect(() => {
    document.title = "Careers — Grain Discovery";
  });

  return (
    <div className="Careers">
      <Header img={headerImg} alt="A field of cut wheat with round straw bales in it"/>

      <div className="section-block">
        <h1>Careers</h1>
        <div className="careers-blurb">
          <p>We’re looking for smart, passionate, and entrepreneurial people to help us on our mission of building the
          future of the food supply chain. If you want to make an impact in a workplace that’s exciting, challenging,
          and diverse, we’d love to have you on board.
              </p>
        </div>
      </div>

      <div className="section-block openings">
        <h2>Our Openings</h2>
        {/*{openings.map((job, index) => {*/}
        {/*  return (*/}
        {/*    <Row key={index}>*/}
        {/*      <Col span={12} className="job">*/}
        {/*        <p className="job-name">{job.title}</p>*/}
        {/*        <p className="job-location">{job.location}</p>*/}
        {/*        {job.duration && <p className="job-duration">{job.duration}</p>}*/}
        {/*      </Col>*/}
        {/*      <Col className="apply" span={12}>*/}
        {/*        <Link to={'/jobs/' + job.link}>*/}
        {/*          <Button type="primary" shape="round" size="large">*/}
        {/*            Apply*/}
        {/*          </Button>*/}
        {/*        </Link>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  )*/}
        {/*})}*/}

      </div>
    </div>
  );
}

export default Careers;