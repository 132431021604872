import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

// components
import {press} from '../../../data/pages/press/pressList';

// assets
import './HomePageNews.scss';
import RightArrowIcon from "../../svg/RightArrowIcon";

const HomePageNews = () => {
    const article = press[0];

    return (
        <div className="home-section-5">
            <div className="title">
                <h2>In the news</h2>
            </div>

            <div className="content">
                <div className="main">
                    <img src={require('../../../assets/images/press/' + article.logo)} alt="News Image" />
                    <div>
                        <h3>
                            <a href={article.link} className="article-title">{article.title}</a>
                        </h3>
                        <p className="article-source">{article.publication} | {article.date}</p>
                        <Link to="/press" className="more-news-link">
                            <span>See more news</span>
                            <RightArrowIcon />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageNews;
