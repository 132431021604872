import React, {useState, useEffect} from 'react';
import { Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

// assets
import twitter from '../../assets/images/logo/twitter.png';
// import medium from '../../assets/images/logo/medium.png';
import logo from '../../assets/images/logo/gd-logo.png';
import './MainLayout.scss';
import SubscribeBar from "../../components/subscribeBar/SubscribeBar";
import FacebookIcon from "../../components/svg/FacebookIcon";
import TwitterIcon from "../../components/svg/TwitterIcon";
import InstagramIcon from "../../components/svg/InstagramIcon";

const MainLayout = ({children}) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return function cleanup() {
        window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  return (
    <Layout className="bg-white">
      {children}
      <div className="footer bg-3">
        <div className="container footer-container">
          <Row className="footer-up" type={"flex"}>
            <Col span={4} lg={4} order={1}>
              <img src={logo} alt="Grain Discovery logo" width="50px" />
            </Col>
            <Col span={10} lg={4} order={1}>
              <ul>
                <li>
                  <Link to="/saygday">Contact Us</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </Col>
            <Col span={10} lg={4} order={1}>
              <ul>
                <li>
                  <a href="https://facebook.com/graindiscovery" target="_blank">
                    <FacebookIcon/> <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/@graindiscovery" target="_blank">
                    <TwitterIcon /> <span>Twitter</span>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/grain_discovery" target="_blank">
                    <InstagramIcon /> <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </Col>
            <Col span={24} order={0} lg={{ span: 12, order: 4}}>
              <SubscribeBar/>
            </Col>
          </Row>
          <div className="copyright">
            <p>© 2022 Grain Discovery. All rights reserved.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MainLayout;
