export const upcomingSpeakingList = 
[

]

export const pastSpeakingList = 
[
    {
        title: "OABA",
        date: "February 23, 2021",
        location: "Guelph, ON (virtual)",
        img: "oaba.png",
        alt: "OABA logo",
        link: "https://www.oaba.on.ca/"
    },
    {
        title: "Plug And Play AgTech",
        date: "February 3, 2021",
        location: "Fargo, ND (virtual)",
        img: "plugAndPlay.png",
        alt: "Plug And Play logo",
        link: "https://www.plugandplaytechcenter.com/resources/tag/agtech/"
    },
    {
        title: "AGRI Tech Venture Forum",
        date: "December 2, 2020",
        location: "Toronto, ON (virtual)",
        img: "agri.png",
        alt: "AGRI Tech Venture Forum",
        link: "https://agritechventureforum.com/"
    },
    {
        title: "CDL, Ag Stream",
        date: "November 18, 2020",
        location: "Calgary, AB (virtual)",
        img: "cdl.png",
        alt: "AGRI Tech Venture Forum",
        link: "https://www.creativedestructionlab.com/streams/ag/"
    },
    {
        title: "Canadian Barley Symposium & BMBRI Triennial Meeting",
        date: "February 14, 2020",
        location: "Winnipeg, MB",
        img: "bcc.png",
        alt: "Barley Council of Canada logo",
        link: "https://www.eventbrite.ca/e/9th-canadian-barley-symposium-24th-bmbri-triennial-meeting-tickets-83975845013"
    },
    {
        title: "Farms.com Precision Agriculture Conference",
        date: "January 29 - 30, 2020",
        location: "London, ON",
        img: "farmscom.png",
        alt: "Farms.com logo",
        link: "https://web.cvent.com/event/3006cd46-0ea6-4da7-a632-0b5cea088e34/summary?rp=00000000-0000-0000-0000-000000000000"
    },
    {
        title: "SouthWest Agriculture Conference",
        date: "January 7 - 8, 2020",
        location: "Ridgetown, ON",
        img: "southWest.png",
        alt: "SouthWest Agriculture Conference",
        link: "https://www.southwestagconference.ca/"
    },
    {
        title: "Farm Journal AgTech Expo",
        date: "December 16 - 17, 2019",
        location: "Indianapolis, IN",
        img: "FarmJournal.png",
        alt: "Farm Journal AgTech Expo logo",
        link: "https://www.farmjournalagtechexpo.com/"
    },
    {
        title: "Grain World Conference",
        date: "November 27 - 28, 2019",
        location: "Saskatoon, SK",
        host: "Grain World",
        img: "grainWorld.png",
        alt: "A green oblong shape next to a blue half-globe.",
        link: "https://grainworldconference.ca/"
    },
    {
        title: "Cultivate",
        date: "November 14, 2019",
        location: "Fargo, ND",
        host: "Emerging Prairie",
        img: "emergingPrairie.jpeg",
        link: "https://www.emergingprairie.com/cultivate/"
    },
    {
        title: "CDL, Blockchain Stream",
        date: "October 23, 2019",
        location: "Toronto, ON",
        host: "Creative Destruction Lab",
        img: "cdl.png",
        link: "https://www.creativedestructionlab.com/"
    },
    {
        title: "Future of Agriculture",
        date: "October 15, 2019",
        location: "Toronto, ON",
        host: "Case IH",
        img: "case.png",
        link: "https://www.caseih.com/northamerica/en-us/home"
    },
    {
        title: "Blockchain in Agriculture",
        date: "October 10, 2019",
        location: "Guelph, ON",
        host: "Ontario Agri Business Association",
        img: "oaba.png",
        link: "https://www.oaba.on.ca/"
    },
    {
        title: "Ag Innovation Showcase",
        date: "September 9 - 11, 2019",
        location: "Minneapolis, MN",
        host: "Ag Innovation",
        img: "Ag-Innovation.png",
        link: "https://www.agshowcase.com/"
    },
    {
        title: "Partnership Meeting",
        date: "August 6 - 8, 2019",
        location: "Louisville, KY",
        host: "American Soybean Association",
        img: "ASA.png",
        link: "https://soygrowers.com/"
    },
    {
        title: "Exploring Agriculture’s Path to the Long Term",
        date: "July 16 -17, 2019",
        location: "Kansas City, MO",
        host: "Federal Reserve Bank of Kansas City",
        img: "KC-Fed.png",
        link: "https://www.kansascityfed.org/publications/research/rscp/agsymp-2019"
    },
    {
        title: "AGM and Convention",
        date: "May 27 - 29, 2019",
        location: "Banff, AB",
        host: "Animal Nutrition Association of Canada", 
        img: "ANAC.png",  
        link: "https://www.anacan.org/anac/index.html"
    },
    {
        title: "AGRI Tech Venture Forum",
        date: "May 15, 2019",
        location: "Toronto, ON",
        img: "agri.png",
        alt: "AGRI Tech Venture Forum",
        link: "https://agritechventureforum.com/"
    },
    {
        title: "Focus on Grain Markets",
        date: "May 8 - 10, 2019",
        location: "Chicago, IL",
        host: "cmdty by barchart",
        img: "Barchart.png",
        link: "https://www.barchart.com/cmdty/resources/events/exchange2019"
    },
    {
        title: "AGM and Policy Forum",
        date: "April 12, 2019",
        location: "Alliston, ON",
        host: "Organic Council of Canada",
        img: "oco.png",
        link: "https://www.organiccouncil.ca/agm/"
    },
    {
        title: "Hyperledger Meetup",
        date: "March 28, 2019",
        location: "Toronto, ON",
        host: "MaRS",
        img: "MaRS.png",
        link: "https://www.meetup.com/Hyperledger-Toronto/events/259134878/"
    },
    {
        title: "Greenstone Conference",
        date: "February 18 - 20, 2019",
        location: "Tampa, FL",
        host: "Greenstone",
        img: "Greenstone.png",
        link: "https://greenstonesystems.com/customer-conference/"
    },
    {
        title: "Blockchain Breakfast",
        date: "October 30, 2018",
        location: "Toronto, ON",
        host: "Jonah Group",
        img: "jonahGroup.png",
        link: "https://www.jonahgroup.com/blog/bc_breakfast"
    },
    {
        title: "Digital Agriculture Conference",
        date: "November 1, 2018",
        location: "Meaford, ON",
        host: "Ag 4.0.2: The Next Big Thing",
        img: "grey-county.png",
        link: "https://www.owensoundsuntimes.com/news/local-news/agriculture-technology-focus-of-conference"
    },
    {
        title: "StableView Tech18",
        date: "October 4, 2018",
        location: "Toronto, ON",
        img: "StableViewTech.png",
        alt: "A hand pinching its fingers together with a white cone shining out of them",
        link: "https://stableview.ca/events/tech18/"
    },
    {
        title: "Ag Innovation Showcase",
        date: "September 10 - 12, 2018",
        location: "St Louis, MO",
        host: "Ag Innovation",
        img: "Ag-Innovation.png",
        link: "https://www.agshowcase.com/"
    },
    {
        title: "CCA - Annual Conference",
        date: "August 14, 2018",
        location: "London, ON",
        host: "Canadian Cattlemen's Association",
        img: "CCA.png",
        link: "http://www.cattle.ca/news-events/cca-annual-general-meeting/"
    },

]