import React, {useState} from 'react';

import img1 from '../../../assets/images/book/1.png'
import img2 from '../../../assets/images/book/2.png'
import img3 from '../../../assets/images/book/3.png'
import './BookPageProducts.scss'
import {Button, Col, Row} from "antd";
import PlayIcon from "../../svg/PlayIcon";
import ModalVideo from "react-modal-video";

const BookPageProducts = () => {
    const [isOpen, setOpen] = useState(false)
    const [videoId, setVideoId] = useState('')

    const playVideo = (videoId) => {
        setVideoId(videoId);
        setOpen(true);
    }

    return (
        <div className="book-section-2">
            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{span: 12}}>
                    <div className="img-container left">
                        <img src={img1} alt="Left Ribbon" />
                    </div>
                </Col>
                <Col span={24} lg={{span: 12}}>
                    <div className="title text-container">
                        <h2>Live Market</h2>
                        <p>
                            Digitize and simplify by creating live bids for your farmers and viewing their offers, confirming deals instantly
                        </p>
                        <Button onClick={() => playVideo("wwtI52eafdA")}
                           className="btn-primary">
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{span: 12}} order={1}>
                    <div className="title text-container right">
                        <h2>Trade Creation</h2>
                        <p>
                            Farmers can easily create new offers (delivered or FOB)
                        </p>
                        <Button className="btn-primary"
                                onClick={() => playVideo("pgIJy3-ndqs")} >
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
                <Col span={24} lg={{span: 12, order: 2}} order={0}>
                    <div className="img-container right">
                        <img src={img2} alt="Left Ribbon" />
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"}>
                <Col span={24} lg={{span: 12}}>
                    <div className="img-container left">
                        <img src={img3} alt="Left Ribbon" />
                    </div>
                </Col>
                <Col span={24} lg={{span: 12}}>
                    <div className="title text-container">
                        <h2>Portfolio</h2>
                        <p>
                            View historical and recent scale tickets, contracts and settlements anytime, anywhere
                        </p>
                        <Button
                                onClick={() => playVideo("OrsENPvUXDQ")}
                                className="btn-primary">
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
            </Row>

            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </div>
    );

}

export default BookPageProducts;
