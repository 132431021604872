import React from 'react';

// components
import {WrappedSubscribeForm} from './subscribe/Subscribe';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import {Row, Col} from 'antd';

// assets
import './SubscribeBar.scss';

const SubscribeBar = () => {
    const mailChimpUrl = "https://graindiscovery.us7.list-manage.com/subscribe/post?u=d7bbcb66546cdec062f65fdfc&amp;id=a5563382a2";

    return (
        <div className="sign-up">
            <div className="message">
                <h2>Sign up to get exclusive news and early access to the marketplace</h2>
            </div>

            <div className="form-container">
                <MailchimpSubscribe
                    url={mailChimpUrl}
                    render={({ subscribe, status, message }) => (
                        <WrappedSubscribeForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </div>
        </div>
    );
}

export default SubscribeBar;
