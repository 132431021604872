import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, Redirect, withRouter, NavLink } from 'react-router-dom';
import {Menu, Affix, Button} from 'antd';

// components in navbar
import Home from '../../pages/home/Home';
import Team from '../../pages/team/Team';
import Book from '../../pages/book/Book';
import Pilots from '../../pages/pilots/Pilots';
import Press from '../../pages/press/Press';
import Careers from '../../pages/careers/Careers';
import ListingLayout from '../../layouts/careers/listingLayout/ListingLayout';
import Contact from '../../pages/contact/Contact';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import { openings } from '../../data/pages/careers/jobOpenings';
import AnimatedLogo from '../animatedLogo/AnimatedLogo';

// assets
import './NavBar.scss';
import ReactGA from 'react-ga';
import FacebookIcon from "../svg/FacebookIcon";
import TwitterIcon from "../svg/TwitterIcon";
import InstagramIcon from "../svg/InstagramIcon";

const NavBar = ({location}) => {
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(false);

    ReactGA.initialize('UA-181372061-1');
    ReactGA.pageview(window.location.pathname);

    useEffect(() => {
        setLoading(false);
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions);
        }
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    return (
        !loading &&
        <div className="NavBar">
            <header className="header">
                <div className="header-container">
                    <div className="row top-row">
                        <div className="col-12 top">
                            <button className={`hamburger hamburger--collapse ${mobileMenu ? 'is-active' : ''}`}
                                    type="button"
                                    onClick={() => setMobileMenu(!mobileMenu)}>
                                <span className="hamburger-box">
                                    <span className="hamburger-inner" />
                                </span>
                            </button>
                            <div className="logo">
                                <Link to="/home" onClick={() => setMobileMenu(false)}>
                                    <AnimatedLogo
                                        delay={1000}/>
                                </Link>
                            </div>
                            <div className={`menu-container ${mobileMenu ? 'open' : ''}`}>
                                <ul className="right">
                                    <li><NavLink activeClassName="active" to="/book" onClick={() => setMobileMenu(false)} >Our Product</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/work" onClick={() => setMobileMenu(false)} >Our Work</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/team" onClick={() => setMobileMenu(false)} >Our Team</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/careers" onClick={() => setMobileMenu(false)} >Careers</NavLink></li>
                                    <li><NavLink activeClassName="active" to='/press' onClick={() => setMobileMenu(false)} >Press</NavLink></li>
                                </ul>
                                <div className="left">
                                    <ul>
                                        <li>
                                            <a href="https://facebook.com/graindiscovery" target="_blank">
                                                <FacebookIcon/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/@graindiscovery" target="_blank">
                                                <TwitterIcon />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/grain_discovery" target="_blank">
                                                <InstagramIcon />
                                            </a>
                                        </li>
                                    </ul>
                                    <Link to="/saygday" className="btn-default ant-btn">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/*<Affix>*/}
            {/*    <Menu theme='light' mode='horizontal' selectedKeys={[location.pathname]} className="top-menu">*/}
            {/*        <Menu.Item key='1' className="logo-button">*/}
            {/*            <div className="logo">*/}
            {/*                <Link to="/home">*/}
            {/*                    <AnimatedLogo*/}
            {/*                    delay={1000}/>*/}
            {/*                </Link>*/}
            {/*            </div>*/}
            {/*        </Menu.Item>*/}
            {/*        <Menu.Item key='2'> <Link to="/simplify" >Elevators</Link></Menu.Item>*/}
            {/*        <Menu.Item key='3'> <Link to="/work" >Our Work</Link></Menu.Item>*/}
            {/*        <Menu.Item key='4'> <Link to="/team">Team</Link></Menu.Item>*/}
            {/*        <Menu.Item key='5'><Link to="/press">Press</Link></Menu.Item>*/}
            {/*        <Menu.Item key='6' className={width >= 485 && 'float-right'}> <Link to="/saygday">Contact Us</Link></Menu.Item>*/}
            {/*        <Menu.Item key='7' className={width >= 485 && 'float-right'}><Link to="/careers">Careers</Link></Menu.Item>*/}
            {/*        /!* <Menu.Item key='6' className={width >= 485 && 'float-right'}> <a href="https://medium.com/grain-discovery">Blog</a></Menu.Item> *!/*/}
            {/*    </Menu>*/}
            {/*</Affix>*/}

            <MainLayout>
                <Switch>
                    <Redirect exact from='/' to='home' />
                    <Route path='/home' component={Home} />
                    <Route path='/book' component={Book} />
                    <Route path='/work' component={Pilots} />
                    <Route path='/team' component={Team} />
                    <Route path='/press' component={Press} />
                    <Route path='/careers' component={Careers} />
                    <Route path='/saygday' component={Contact} />

                    {openings.map((job, index) => {
                        return (
                            <Route
                                key={index}
                                path={'/jobs/' + job.link}
                                render={(props) => <ListingLayout {...props} opening={job} />}
                            />
                        )
                    })}

                </Switch>
            </MainLayout>
        </div>
    );
}

export default withRouter(NavBar);

