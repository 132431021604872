import React, {useState, useEffect} from 'react';
import {HashLink as Link} from 'react-router-hash-link';

// components
import Header from '../../components/header/Header';

// assets
import headerImg from '../../assets/images/headers/wheat.jpg';
import { press } from '../../data/pages/press/pressList';
import GridLayout from '../../layouts/gridLayout/GridLayout';
import { upcomingSpeakingList, pastSpeakingList } from '../../data/pages/press/speakingEngagements';
import arrow from '../../assets/images/icons/right-arrow.svg';
import './Press.css';

const Press = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    document.title = "Press — Grain Discovery"

    return function cleanup() {
        window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  }

  const formatData = (data) => {
    return(data.map((event, index) => {
      return(
        <div key={index} className="speaking-engagements">
          <div className="speaking-img">
            <img src={require('../../assets/images/press/speaking/' + event.img)} width="120px" alt={event.alt ? event.alt : event.host}></img>
          </div>
          <a href={event.link} target="_blank" rel="noopener noreferrer" ><p className="speaking-title">{event.title}</p></a>
          <div className="speaking-info">
            <p>{event.date}</p>
            <div className="date-location">
              <p >{event.host && event.host}</p>
              <p >{event.location}</p>
            </div>
          </div>
        </div>
      )
      
    }));
  }

  const formatPress = (data) => {
    return(data.map((article, index) => {
      return(
        <div key={index} className="press-item">
          <div className="press-img">
            <img src={require('../../assets/images/press/' + article.logo)} width="120px" alt={article.alt}></img>
          </div>
          <a href={article.link} target="_blank" rel="noopener noreferrer" ><p>{article.title}</p></a>
          <p>{article.publication} | {article.date}</p>
        </div>
      )
      
    }));
  }

  return (
    <div className="Press">
      <Header img={headerImg} alt="A field of golden wheat with a blue sky in the background"/>
      <div className="section-block container">
        <div className="title">
          <h1>Press</h1>
          <p>We're disrupting the agriculture industry. Check out our story so far.</p>
          <Link to="/press#speaking">
            <p className="link-to-speaking">See where we're speaking<span><img width="20px" src={arrow} alt="right arrow"></img></span></p>
          </Link>
        </div>
      </div>

      <div className="section-block container">
        <GridLayout numCols={width >= 1000 ? 4 : 2} data={formatPress(press)} gutter={16}/>
      </div>

      <div className="section-block container" id={"speaking"}>
        <h2>Where we're speaking</h2>
        <div className="speaking-list">
          <GridLayout numCols={width >= 1000 ? 4 : 2} data={formatData(upcomingSpeakingList)} />
        </div>
        <h3>Past speaking events</h3>
        <div className="speaking-list">
          <GridLayout numCols={width >= 1000 ? 4 : 2} data={formatData(pastSpeakingList)} />
        </div>
      </div>

    </div>
  );
}

export default Press;
