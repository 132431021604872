import React, {useEffect, useRef, useState} from 'react';
import {scroller, Element} from 'react-scroll'
import {Button, Col, Row} from 'antd';

// components
import WhatWeDo from "../../components/home/whatWeDo/WhatWeDo";
import InteractiveMockup from '../../components/home/interactiveMockup/InteractiveMockup';
import HomePageNews from '../../components/home/homePageNews/HomePageNews';
import MarketYourGrain from "../../components/home/marketYourGrain/MarketYourGrain";
import GrainIcon from "../../components/svg/GrainIcon";
import FarmerIcon from "../../components/svg/FarmerIcon";
import DownloadModal from "../../components/downloadModal/DownloadModal";

//assets
import './Home.scss';
import img from '../../assets/images/headers/scott-goodwill.jpg';


const Home = () => {
  const myRef = useRef(null)

  useEffect(() => {
    document.title = "Grain Discovery"
  }, []);

  const scrollTo = () => {
    scroller.scrollTo('scroll-to-element', {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);

  const openDownloadModal = (shouldShowDemo) => {
    setShowDemoForm(shouldShowDemo === true);
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  return (
    <div className="home">
      <div className="banner" style={{backgroundImage: `url(${img})`}}>
        <div className="banner-container">
          <div className="title-container">
            <h1 className="title">Welcome to the <span>Future of Agriculture</span></h1>
          </div>

          <h4 className="sub-title">
            An online marketplace leveraging blockchain technology to create efficient, transparent, and secure transactions.
          </h4>

          <Row type={"flex"} className="banner-card">
            <Col className="card" lg={12} span={24}>
              <div>
                <GrainIcon />
                <h3>Grain Buyers</h3>
                <p>
                  Modernize your business with your own personalized grain origination and management system
                </p>
              </div>
              <Button className="btn-primary" onClick={() => openDownloadModal(true)}>Bring Your Elevator Online</Button>
            </Col>

            <Col className="card" lg={12} span={24}>
              <div>
                <FarmerIcon />
                <h3>Farmers</h3>
                <p>
                  Manage your portfolio online and sell instantly
                </p>
              </div>
              <Button className="btn-primary" onClick={openDownloadModal}>Download the App(s)</Button>
            </Col>
          </Row>

          <div className="scroll-wrapper">
            <span>Scroll down to learn more</span>
            <div className="scroll" onClick={scrollTo} />
          </div>
        </div>
      </div>

      <Element name="scroll-to-element">
        <div className="container section">
          <WhatWeDo />
        </div>
      </Element>

      <div className="container-fluid section bg-1">
        <InteractiveMockup />
      </div>

      <div className="container section">
        <MarketYourGrain openDownloadModal={openDownloadModal}/>
      </div>

      <div className="section bg-2 home-section-4">
        <div className="container">
          <p className="speech">“Our government is committed to supporting Canada’s soybean sector, and this innovative project will strengthen consumer trust and ensure Ontario producers are able to deliver top-quality products for Canadians and markets around the world. Science and innovation are key to ensuring that we can continue to meet demands for our safe and nutritious products at home and abroad”</p>
          <br/>
          <p className="j-title">-Marie-Claude Bibeau, Canadian Minister of Agriculture and Agri-Food</p>
        </div>
      </div>

      <div className="section container">
        <HomePageNews />
      </div>
      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={showDemoForm} />
    </div>
  );

}


export default Home;
