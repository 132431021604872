import React, {useEffect, useState} from 'react';

// components
import AccessOurPlatform from "../../components/book/accessOurPlatform/AccessOurPlatform";
import BookPageProducts from "../../components/book/bookPageProducts/BookPageProducts";
import DownloadModal from "../../components/downloadModal/DownloadModal";

//assets
import './Book.scss';
import img from '../../assets/images/headers/book.jpg';
import logo from '../../assets/images/logo/logo.png';
import {Button} from "antd";

const Book = () => {

  useEffect(() => {
    document.title = "Book a Demo — Grain Discovery"
  });

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const openDownloadModal = () => {
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  return (
    <div className="book">
      <div className="banner" style={{backgroundImage: `url(${img})`}}>
        <div className="banner-container">
          <div className="title-container">
            <h1 className="title">A Better Experience For <br/><span>Grain Buyers And Farmers</span></h1>
          </div>
          <Button className="btn-primary" onClick={openDownloadModal}>Book A Demo</Button>
        </div>
      </div>

      <div className="container">
        <AccessOurPlatform />
      </div>

      <div className="container-fluid section">
        <BookPageProducts />
      </div>

      <div className="container">
        <div className="section book-section-3">
          <div className="content bg-1">
            <div className="logo-wrapper">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            <p>Grain Discovery will continue to build new features to further enhance the grain marketing experience for the elevator and the farmer.</p>
            <Button className="btn-primary" onClick={openDownloadModal}>Book A Demo</Button>
          </div>
        </div>
      </div>
      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={true} />
    </div>
  );

}


export default Book;
